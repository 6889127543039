.about__container{
    display: grid;
    grid-template-columns: 22% 45%;
    gap: 2%;
}
.about__me{
    width: 500%;
    height: 90%;
    aspect-ratio: 0.7/1;
    border-radius:5rem;
    background: linear-gradient(45deg,transparent,var(--color-primary),transparent);
    display: grid;
    place-items: center;
}
.about__cards{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1.5rem;
    margin-top: 10px;
}
.about__card{
    background-color: var(--color-bg);
    border: 2px;
    border-radius: 3rem;
    padding: 1rem;
    width: 5cm;
    text-align: center;
    transition:var(--transition);
}
.about__card:hover{
    background:var(--color-bg-variant) ;
    border-color: var(--color-primary);
    cursor:default;
}

.About__Icon{
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}
.about__card h5{
    font-size: 0.95rem;
}
.about__card small{
    font-size: 0.7rem;
    color: var(--color-light);
}
.mi{
    margin: 1rem 0 2.6rem;
    color:var(--color-white);
}
.bt{
    width: max-content;
    display: inline-block;
    background:var(--color-bg); color:var(--color-primary);
    padding: 0.75rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}
.bt:hover{
 background: var(--color-white);
 color: var(--color-bg);
 border-color: transparent
}
@media screen and (max-width:1280px) {
  .about__me-image{
    display: none;
  }
}
@media screen and (max-width:1024px) {
  .about__container{
    grid-template-columns: 1fr;
    gap: 0;
}
.about__me{
    width: 65%;
    margin: 1rem;
}
.mi{
    margin: 1rem 0 1.5rem;
}
.about__me{
  display: none;
}
.about__cards{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 20%;
}
.about__card{
  position: relative;
  right: 2rem;
}
.about__content{
    text-align: center;
}
.mi{
    margin: 1.5rem 0;
}
}
@media screen and (max-width:600px) {
    .about__container{
        grid-template-columns: 1fr;
        gap: 0;
    }
    .about__me{
        width: 65%;
        margin: 1rem;
    }
    .mi{
        margin: 1rem 0 1.5rem;
    }
    .about__me{
      display: none;
    }
    .about__cards{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 20%;
    }
    .about__card{
      position: relative;
      right: 2rem;
    }
    .about__content{
        text-align: center;
    }
    .mi{
        margin: 1.5rem 0;
    }


    #container {
      display: none;
    }
    .steam {

      display: none;
    }
    
    #steam1 {
      -webkit-animation: steam1 4s ease-out infinite;
      animation: steam1 4s ease-out infinite;
    }
    
    #steam3 {
      -webkit-animation: steam1 4s ease-out 1s infinite;
      animation: steam1 4s ease-out 1s infinite;
    }
    
    @-webkit-keyframes steam1 {
      1% {transform: translateY(0) translateX(0) scale(0.25); opacity: 0.2;}
      100% {transform: translateY(-200px) translateX(-20px) scale(1); opacity: 0;}
    }
    
    @keyframes steam1 {
      0% {transform: translateY(0) translateX(0) scale(0.25); opacity: 0.2;}
      100% {transform: translateY(-200px) translateX(-20px) scale(1); opacity: 0;}
    }
    @-webkit-keyframes steam1 {
      1% {transform: translateY(0) translateX(0) scale(0.25); opacity: 0.2;}
      100% {transform: translateY(-200px) translateX(-20px) scale(1); opacity: 0;}
    }
    
    @keyframes steam1 {
      0% {transform: translateY(0) translateX(0) scale(0.25); opacity: 0.2;}
      100% {transform: translateY(-200px) translateX(-20px) scale(1); opacity: 0;}
    }
    #steam2 {
      -webkit-animation: steam2 4s ease-out 0.5s infinite;
      animation: steam2 4s ease-out 0.5s infinite;
    }
    
    #steam4 {
      -webkit-animation: steam2 4s ease-out 1.5s infinite;
      animation: steam2 4s ease-out 1.5s infinite;
    }
    
    @-webkit-keyframes steam2 {
      0% {transform: translateY(0) translateX(0) scale(0.25); opacity: 0.2;}
      100% {transform: translateY(-200px) translateX(200px) scale(1); opacity: 0;}
    }
    
    @keyframes steam2 {
      0% {transform: translateY(0) translateX(0) scale(0.25); opacity: 0.2;}
      100% {transform: translateY(-200px) translateX(20px) scale(1); opacity: 0;}
    }
     
    @-webkit-keyframes steam2 {
      0% {transform: translateY(0) translateX(0) scale(0.25); opacity: 0.2;}
      100% {transform: translateY(-200px) translateX(200px) scale(1); opacity: 0;}
    }
    
    @keyframes steam2 {
      0% {transform: translateY(0) translateX(0) scale(0.25); opacity: 0.2;}
      100% {transform: translateY(-200px) translateX(20px) scale(1); opacity: 0;}
    }
    #cup {
      display: none;
    }
    
    #cup-body {
      display: none;
    }
    
    #cup-shade {
      display: none;
    }
    
    #cup-handle {
      display: none;
    }
    
    #saucer {
      display: none;
    }
    
    #shadow {
      display: none;
    }
}



  #container {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .steam {
    position: relative;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-color: rgb(236, 230, 230);
    margin-top: -60px;
    margin-left: 75px;
    z-index: 0;
    opacity: 1;
  }
  
  #steam1 {
    -webkit-animation: steam1 4s ease-out infinite;
    animation: steam1 4s ease-out infinite;
  }
  
  #steam3 {
    -webkit-animation: steam1 4s ease-out 1s infinite;
    animation: steam1 4s ease-out 1s infinite;
  }
  
  @-webkit-keyframes steam1 {
    1% {transform: translateY(0) translateX(0) scale(0.25); opacity: 0.2;}
    100% {transform: translateY(-200px) translateX(-20px) scale(1); opacity: 0;}
  }
  
  @keyframes steam1 {
    0% {transform: translateY(0) translateX(0) scale(0.25); opacity: 0.2;}
    100% {transform: translateY(-200px) translateX(-20px) scale(1); opacity: 0;}
  }
  @-webkit-keyframes steam1 {
    1% {transform: translateY(0) translateX(0) scale(0.25); opacity: 0.2;}
    100% {transform: translateY(-200px) translateX(-20px) scale(1); opacity: 0;}
  }
  
  @keyframes steam1 {
    0% {transform: translateY(0) translateX(0) scale(0.25); opacity: 0.2;}
    100% {transform: translateY(-200px) translateX(-20px) scale(1); opacity: 0;}
  }
  #steam2 {
    -webkit-animation: steam2 4s ease-out 0.5s infinite;
    animation: steam2 4s ease-out 0.5s infinite;
  }
  
  #steam4 {
    -webkit-animation: steam2 4s ease-out 1.5s infinite;
    animation: steam2 4s ease-out 1.5s infinite;
  }
  
  @-webkit-keyframes steam2 {
    0% {transform: translateY(0) translateX(0) scale(0.25); opacity: 0.2;}
    100% {transform: translateY(-200px) translateX(200px) scale(1); opacity: 0;}
  }
  
  @keyframes steam2 {
    0% {transform: translateY(0) translateX(0) scale(0.25); opacity: 0.2;}
    100% {transform: translateY(-200px) translateX(20px) scale(1); opacity: 0;}
  }
   
  @-webkit-keyframes steam2 {
    0% {transform: translateY(0) translateX(0) scale(0.25); opacity: 0.2;}
    100% {transform: translateY(-200px) translateX(200px) scale(1); opacity: 0;}
  }
  
  @keyframes steam2 {
    0% {transform: translateY(0) translateX(0) scale(0.25); opacity: 0.2;}
    100% {transform: translateY(-200px) translateX(20px) scale(1); opacity: 0;}
  }
  #cup {
    z-index: 1;
  }
  
  #cup-body {
    position: absolute;
    height: 180px;
    width: 300px;
    border-radius: 0 0 150px 150px;
    background-color: #fff;
    margin: auto;
    display: inline-block;
    overflow: hidden;
    z-index: 5;
  }
  
  #cup-shade {
    position: relative;
    height: 300px;
    width: 200px;
    background-color: #F3F3F3;
    display: inline-block;
    margin-left: 42%;
    margin-top: -3px;
    transform: rotate(50deg);
    z-index: 1;
  }
  
  #cup-handle {
    position: relative;
    height: 75px;
    width: 80px;
    border-radius: 0 150px 150px 0;
    border: 15px solid #F3F3F3;
    margin-bottom: 95px;
    margin-left: 250px;
    display: inline-block;
    z-index: 0;
  }
  
  #saucer {
    position: absolute;
    height: 40px;
    width: 300px;  
    border-radius: 0 0 100px 100px;
    background-color: #F9F9F9;
    margin-top: -32px;
    margin-left: 5px;
    z-index: 10;
  }
  
  #shadow {
    height: 10px;
    width: 300px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: 6px;
    background-color: #396c83;
  }

