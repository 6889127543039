header{
    height: 100vh;
    padding-top: 7rem;
    position: relative;
}
.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}
.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
    position: relative;
}

.Socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}
.Socials::after{
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}
.me{
    display: flex;
    width: 24rem;
    height: 24rem;
    z-index: 10;
    position: relative;
    left: calc(55% - 14rem);
    margin-top: 0.5rem;
    border-radius: 12rem 12rem 12 0;
    overflow: hidden;
    padding: 2rem 1rem 1rem 1rem;
}
.scroll_down{
position:absolute;
right: -2.3rem;
bottom: 5rem;
transform: rotate(90deg);
font-size:larger;
}
@media screen and ((max-width:1220px) ) {
     header{
        height: 100vh;
       }
       .Socials,.scroll_down{
        margin: -9%;
        margin-left: 2%;
        display: relative;
       }
       .me{
        display: flex;
        background: transparent;
        width: 14rem;
        height: 24rem;
        z-index: 10;
        position: relative;
        left: calc(80% - 14rem);
        margin-top: 0.5rem;
        border-radius: 12rem 12rem 12 0;
        overflow: hidden;
        padding: 2rem 1rem 1rem 1rem;
    }
}
@media screen and ((max-width:600px)) {
   header{
    height: 100vh;
   }
   .scroll_down{
    transform: rotate(0deg);
    right: 0.5rem;
    font-size: x-large;
   }
   .me{
    display: flex;
    background: transparent;
    width: 4rem;
    height: 4rem;
    z-index: 10;
    position: relative;
    left: calc(70% - 14rem);
    margin-top: 0.5rem;
    border-radius: 12rem 12rem 12 0;
    overflow: hidden;
    padding: 2rem 1rem 1rem 1rem;
}
.me{
    display: none;
   }
}