@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
*{
 margin: 0;
 padding: 0;
 border: 0;
 outline: 0;
 box-sizing: border-box;
 list-style: none;
 text-decoration: none;
}
:root{
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: #rgba(77,181,255,0.4);
    --color-white: #fff;
    --color-light: rgba(255,255,255,0.6);
    --container-width-lg:75%;
    --containe-width-md:86%;
    --container-width-ms:90%;
    --transition:all 400ms ease;
}

html{
    scroll-behavior: smooth;
}
::-webkit-scrollbar{
    display:none; /* improve */
}
body{
    font-family: 'Poppins', sans-serif;
    color: var(--color-white);
    line-height: 1.7;
}
.container{
    width: var(--container-width-lg);
    margin: 0 auto;
}
h1,h2,h3,h4,h5{
    font-weight: 500;
}
h1{
    font-size: 2.5rem;
}
h5{
    font-size: 1.3rem;
    margin: 1rem;
}
section{
    margin: 8rem;
}
section > h2,section >h5{
    text-align: center;
    color: var(--color-light);
    height: 5vh;
}
section>h2{
    color:var(--color-primary);
    margin-bottom: 3rem;
}
.text-ligth{
    color:var(--color-light);
    font-size: 1.2rem;
}
a{
    color: var(--color-primary);
    transition: var(--transition);
}
a:hover{
    color: var(--color-white);
}
.btn{
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}
.btn:hover{
 background: var(--color-white);
 color: var(--color-bg);
 border-color: transparent;
}
.btn-primary{
    background: var(--color-primary);
    color: var(--color-bg);
}
img{
    display: block;
    width: 50%;
    object-fit: cover;
}
@media screen and (max-width:1024px) {
    .container{
        width:var(--containe-width-md);
    }
    section{
        margin-top: 6rem;
    }
}
@media screen and (max-width:600px) {
    .container{
        width:var(--container-width-ms);
    }
    section > h2{
        margin-top: 2rem;
    }
}


@keyframes move {
    100% {
        transform: translate3d(0, 0, 1px) rotate(360deg);
    }
}


.background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #3E1E68;
    overflow:scroll;
}

.background span {
    width: 17vmin;
    height: 17vmin;
    border-radius: 17vmin;
    backface-visibility:hidden;
    position: absolute;
    animation: move;
    animation-duration: 32;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    opacity: 0.3;
}s


.background span:nth-child(0) {
    color: #914b29;
    top: 38%;
    left: 25%;
    animation-duration: 167s;
    animation-delay: -118s;
    transform-origin: 8vw -9vh;
    box-shadow: -37vmin 0 4.72308404058913vmin currentColor;
}
.background span:nth-child(1) {
    color: #bc2d08;
    top: 57%;
    left: 8%;
    animation-duration: 142s;
    animation-delay: -130s;
    transform-origin: 17vw -1vh;
    box-shadow: -34vmin 0 4.605214951106792vmin currentColor;
}
.background span:nth-child(2) {
    color: #E45A84;
    top: 55%;
    left: 4%;
    animation-duration: 43s;
    animation-delay: -128s;
    transform-origin: 2vw -7vh;
    box-shadow: 34vmin 0 4.677569591067222vmin currentColor;
}
.background span:nth-child(3) {
    color: #583C87;
    top: 69%;
    left: 45%;
    animation-duration: 96s;
    animation-delay: -67s;
    transform-origin: 16vw -17vh;
    box-shadow: 34vmin 0 4.905195624516644vmin currentColor;
}
.background span:nth-child(4) {
    color: #FFACAC;
    top: 83%;
    left: 11%;
    animation-duration: 40s;
    animation-delay: -2s;
    transform-origin: 9vw 13vh;
    box-shadow: -34vmin 0 4.545621916627195vmin currentColor;
}
.background span:nth-child(5) {
    color: #FFACAC;
    top: 83%;
    left: 76%;
    animation-duration: 102s;
    animation-delay: -41s;
    transform-origin: -2vw -18vh;
    box-shadow: -34vmin 0 4.519323476717205vmin currentColor;
}
.background span:nth-child(6) {
    color: #bc2d08;
    top: 89%;
    left: 23%;
    animation-duration: 111s;
    animation-delay: -30s;
    transform-origin: 12vw -24vh;
    box-shadow: -34vmin 0 5.102326201581666vmin currentColor;
}
.background span:nth-child(7) {
    color: #914b29;
    top: 31%;
    left: 79%;
    animation-duration: 75s;
    animation-delay: -133s;
    transform-origin: 2vw -15vh;
    box-shadow: 34vmin 0 4.274951778559119vmin currentColor;
}
.background span:nth-child(8) {
    color: #bc2d08;
    top: 97%;
    left: 91%;
    animation-duration: 58s;
    animation-delay: -100s;
    transform-origin: 21vw 0vh;
    box-shadow: -34vmin 0 4.257554960409644vmin currentColor;
}
.background span:nth-child(9) {
    color: #583C87;
    top: 14%;
    left: 75%;
    animation-duration: 22s;
    animation-delay: -143s;
    transform-origin: -18vw 15vh;
    box-shadow: 34vmin 0 4.529618569764583vmin currentColor;
}
.background span:nth-child(10) {
    color: #bc2d08;
    top: 45%;
    left: 20%;
    animation-duration: 71s;
    animation-delay: -27s;
    transform-origin: -19vw -12vh;
    box-shadow: 34vmin 0 5.140357580477675vmin currentColor;
}
.background span:nth-child(11) {
    color: #914b29;
    top: 63%;
    left: 84%;
    animation-duration: 57s;
    animation-delay: -106s;
    transform-origin: -22vw -13vh;
    box-shadow: -34vmin 0 4.976397818984253vmin currentColor;
}
.background span:nth-child(12) {
    color: #583C87;
    top: 14%;
    left: 13%;
    animation-duration: 61s;
    animation-delay: -62s;
    transform-origin: 10vw 21vh;
    box-shadow: -34vmin 0 4.713508509180956vmin currentColor;
}
.background span:nth-child(13) {
    color: #E45A84;
    top: 7%;
    left: 73%;
    animation-duration: 21s;
    animation-delay: -4s;
    transform-origin: 6vw -16vh;
    box-shadow: 34vmin 0 4.467105091756775vmin currentColor;
}
.background span:nth-child(14) {
    color: #583C87;
    top: 52%;
    left: 84%;
    animation-duration: 137s;
    animation-delay: -59s;
    transform-origin: 13vw -18vh;
    box-shadow: -34vmin 0 4.534312089203363vmin currentColor;
}
.background span:nth-child(15) {
    color: #914b29;
    top: 53%;
    left: 94%;
    animation-duration: 144s;
    animation-delay: -104s;
    transform-origin: -9vw -3vh;
    box-shadow: -34vmin 0 4.330266620127379vmin currentColor;
}
.background span:nth-child(16) {
    color: #914b29;
    top: 14%;
    left: 14%;
    animation-duration: 119s;
    animation-delay: -124s;
    transform-origin: 25vw -8vh;
    box-shadow: -34vmin 0 5.182863385351318vmin currentColor;
}
.background span:nth-child(17) {
    color: #E45A84;
    top: 51%;
    left: 34%;
    animation-duration: 63s;
    animation-delay: -118s;
    transform-origin: -11vw 10vh;
    box-shadow: 34vmin 0 4.2585086775251vmin currentColor;
}
.background span:nth-child(18) {
    color: #FFACAC;
    top: 95%;
    left: 41%;
    animation-duration: 10s;
    animation-delay: -158s;
    transform-origin: 3vw 25vh;
    box-shadow: 34vmin 0 5.073930457707828vmin currentColor;
}
.background span:nth-child(19) {
    color: #583C87;
    top: 94%;
    left: 93%;
    animation-duration: 16s;
    animation-delay: -107s;
    transform-origin: 12vw 8vh;
    box-shadow: -34vmin 0 4.5672425386182836vmin currentColor;
}













.background span:nth-child(20) {
    color: #914b29;
    top: 238%;
    left: 25%;
    animation-duration: 167s;
    animation-delay: -118s;
    transform-origin: 8vw -9vh;
    box-shadow: -37vmin 0 4.72308404058913vmin currentColor;
}
.background span:nth-child(21) {
    color: #bc2d08;
    top: 257%;
    left: 8%;
    animation-duration: 142s;
    animation-delay: -130s;
    transform-origin: 17vw -1vh;
    box-shadow: -34vmin 0 4.605214951106792vmin currentColor;
}
.background span:nth-child(22) {
    color: #E45A84;
    top: 255%;
    left: 4%;
    animation-duration: 43s;
    animation-delay: -128s;
    transform-origin: 2vw -7vh;
    box-shadow: 34vmin 0 4.677569591067222vmin currentColor;
}
.background span:nth-child(23) {
    color: #583C87;
    top: 269%;
    left: 45%;
    animation-duration: 96s;
    animation-delay: -67s;
    transform-origin: 16vw -17vh;
    box-shadow: 34vmin 0 4.905195624516644vmin currentColor;
}
.background span:nth-child(24) {
    color: #FFACAC;
    top: 283%;
    left: 11%;
    animation-duration: 40s;
    animation-delay: -2s;
    transform-origin: 9vw 13vh;
    box-shadow: -34vmin 0 4.545621916627195vmin currentColor;
}
.background span:nth-child(25) {
    color: #FFACAC;
    top: 283%;
    left: 76%;
    animation-duration: 102s;
    animation-delay: -41s;
    transform-origin: -2vw -18vh;
    box-shadow: -34vmin 0 4.519323476717205vmin currentColor;
}
.background span:nth-child(26) {
    color: #bc2d08;
    top:289%;
    left: 23%;
    animation-duration: 111s;
    animation-delay: -30s;
    transform-origin: 12vw -24vh;
    box-shadow: -34vmin 0 5.102326201581666vmin currentColor;
}
.background span:nth-child(27) {
    color: #914b29;
    top: 231%;
    left: 79%;
    animation-duration: 75s;
    animation-delay: -133s;
    transform-origin: 2vw -15vh;
    box-shadow: 34vmin 0 4.274951778559119vmin currentColor;
}
.background span:nth-child(28) {
    color: #bc2d08;
    top: 297%;
    left: 91%;
    animation-duration: 58s;
    animation-delay: -100s;
    transform-origin: 21vw 0vh;
    box-shadow: -34vmin 0 4.257554960409644vmin currentColor;
}
.background span:nth-child(29) {
    color: #583C87;
    top: 214%;
    left: 75%;
    animation-duration: 22s;
    animation-delay: -143s;
    transform-origin: -18vw 15vh;
    box-shadow: 34vmin 0 4.529618569764583vmin currentColor;
}
.background span:nth-child(30) {
    color: #bc2d08;
    top: 245%;
    left: 20%;
    animation-duration: 71s;
    animation-delay: -27s;
    transform-origin: -19vw -12vh;
    box-shadow: 34vmin 0 5.140357580477675vmin currentColor;
}
.background span:nth-child(31) {
    color: #914b29;
    top: 263%;
    left: 84%;
    animation-duration: 57s;
    animation-delay: -106s;
    transform-origin: -22vw -13vh;
    box-shadow: -34vmin 0 4.976397818984253vmin currentColor;
}
.background span:nth-child(32) {
    color: #583C87;
    top: 214%;
    left: 13%;
    animation-duration: 61s;
    animation-delay: -62s;
    transform-origin: 10vw 21vh;
    box-shadow: -34vmin 0 4.713508509180956vmin currentColor;
}
.background span:nth-child(33) {
    color: #E45A84;
    top: 207%;
    left: 73%;
    animation-duration: 21s;
    animation-delay: -4s;
    transform-origin: 6vw -16vh;
    box-shadow: 34vmin 0 4.467105091756775vmin currentColor;
}
.background span:nth-child(34) {
    color: #583C87;
    top: 252%;
    left: 84%;
    animation-duration: 137s;
    animation-delay: -59s;
    transform-origin: 13vw -18vh;
    box-shadow: -34vmin 0 4.534312089203363vmin currentColor;
}
.background span:nth-child(35) {
    color: #914b29;
    top: 253%;
    left: 94%;
    animation-duration: 144s;
    animation-delay: -104s;
    transform-origin: -9vw -3vh;
    box-shadow: -34vmin 0 4.330266620127379vmin currentColor;
}
.background span:nth-child(36) {
    color: #914b29;
    top: 214%;
    left: 14%;
    animation-duration: 119s;
    animation-delay: -124s;
    transform-origin: 25vw -8vh;
    box-shadow: -34vmin 0 5.182863385351318vmin currentColor;
}
.background span:nth-child(37) {
    color: #E45A84;
    top: 251%;
    left: 34%;
    animation-duration: 63s;
    animation-delay: -118s;
    transform-origin: -11vw 10vh;
    box-shadow: 34vmin 0 4.2585086775251vmin currentColor;
}
.background span:nth-child(38) {
    color: #FFACAC;
    top: 295%;
    left: 41%;
    animation-duration: 10s;
    animation-delay: -158s;
    transform-origin: 3vw 25vh;
    box-shadow: 34vmin 0 5.073930457707828vmin currentColor;
}
.background span:nth-child(39) {
    color: #583C87;
    top: 294%;
    left: 93%;
    animation-duration: 16s;
    animation-delay: -107s;
    transform-origin: 12vw 8vh;
    box-shadow: -34vmin 0 4.5672425386182836vmin currentColor;
}





.background span:nth-child(40) {
    color: #914b29;
    top: 438%;
    left: 25%;
    animation-duration: 167s;
    animation-delay: -118s;
    transform-origin: 8vw -9vh;
    box-shadow: -37vmin 0 4.72308404058913vmin currentColor;
}
.background span:nth-child(41) {
    color: #bc2d08;
    top: 457%;
    left: 8%;
    animation-duration: 142s;
    animation-delay: -130s;
    transform-origin: 17vw -1vh;
    box-shadow: -34vmin 0 4.605214951106792vmin currentColor;
}
.background span:nth-child(42) {
    color: #E45A84;
    top: 455%;
    left: 4%;
    animation-duration: 43s;
    animation-delay: -128s;
    transform-origin: 2vw -7vh;
    box-shadow: 34vmin 0 4.677569591067222vmin currentColor;
}
.background span:nth-child(43) {
    color: #583C87;
    top: 469%;
    left: 45%;
    animation-duration: 96s;
    animation-delay: -67s;
    transform-origin: 16vw -17vh;
    box-shadow: 34vmin 0 4.905195624516644vmin currentColor;
}
.background span:nth-child(44) {
    color: #FFACAC;
    top: 483%;
    left: 11%;
    animation-duration: 40s;
    animation-delay: -2s;
    transform-origin: 9vw 13vh;
    box-shadow: -34vmin 0 4.545621916627195vmin currentColor;
}
.background span:nth-child(45) {
    color: #FFACAC;
    top: 483%;
    left: 76%;
    animation-duration: 102s;
    animation-delay: -41s;
    transform-origin: -2vw -18vh;
    box-shadow: -34vmin 0 4.519323476717205vmin currentColor;
}
.background span:nth-child(46) {
    color: #bc2d08;
    top: 489%;
    left: 23%;
    animation-duration: 111s;
    animation-delay: -30s;
    transform-origin: 12vw -24vh;
    box-shadow: -34vmin 0 5.102326201581666vmin currentColor;
}
.background span:nth-child(47) {
    color: #914b29;
    top: 431%;
    left: 79%;
    animation-duration: 75s;
    animation-delay: -133s;
    transform-origin: 2vw -15vh;
    box-shadow: 34vmin 0 4.274951778559119vmin currentColor;
}
.background span:nth-child(48) {
    color: #bc2d08;
    top: 497%;
    left: 91%;
    animation-duration: 58s;
    animation-delay: -100s;
    transform-origin: 21vw 0vh;
    box-shadow: -34vmin 0 4.257554960409644vmin currentColor;
}
.background span:nth-child(49) {
    color: #583C87;
    top: 414%;
    left: 75%;
    animation-duration: 22s;
    animation-delay: -143s;
    transform-origin: -18vw 15vh;
    box-shadow: 34vmin 0 4.529618569764583vmin currentColor;
}
.background span:nth-child(50) {
    color: #bc2d08;
    top: 445%;
    left: 20%;
    animation-duration: 71s;
    animation-delay: -27s;
    transform-origin: -19vw -12vh;
    box-shadow: 34vmin 0 5.140357580477675vmin currentColor;
}
.background span:nth-child(51) {
    color: #914b29;
    top: 463%;
    left: 84%;
    animation-duration: 57s;
    animation-delay: -106s;
    transform-origin: -22vw -13vh;
    box-shadow: -34vmin 0 4.976397818984253vmin currentColor;
}
.background span:nth-child(52) {
    color: #583C87;
    top: 414%;
    left: 13%;
    animation-duration: 61s;
    animation-delay: -62s;
    transform-origin: 10vw 21vh;
    box-shadow: -34vmin 0 4.713508509180956vmin currentColor;
}
.background span:nth-child(53) {
    color: #E45A84;
    top: 407%;
    left: 73%;
    animation-duration: 21s;
    animation-delay: -4s;
    transform-origin: 6vw -16vh;
    box-shadow: 34vmin 0 4.467105091756775vmin currentColor;
}
.background span:nth-child(54) {
    color: #583C87;
    top: 452%;
    left: 84%;
    animation-duration: 137s;
    animation-delay: -59s;
    transform-origin: 13vw -18vh;
    box-shadow: -34vmin 0 4.534312089203363vmin currentColor;
}
.background span:nth-child(55) {
    color: #914b29;
    top: 453%;
    left: 94%;
    animation-duration: 144s;
    animation-delay: -104s;
    transform-origin: -9vw -3vh;
    box-shadow: -34vmin 0 4.330266620127379vmin currentColor;
}
.background span:nth-child(56) {
    color: #914b29;
    top: 414%;
    left: 14%;
    animation-duration: 119s;
    animation-delay: -124s;
    transform-origin: 25vw -8vh;
    box-shadow: -34vmin 0 5.182863385351318vmin currentColor;
}
.background span:nth-child(57) {
    color: #E45A84;
    top: 451%;
    left: 34%;
    animation-duration: 63s;
    animation-delay: -118s;
    transform-origin: -11vw 10vh;
    box-shadow: 34vmin 0 4.2585086775251vmin currentColor;
}
.background span:nth-child(58) {
    color: #FFACAC;
    top: 495%;
    left: 41%;
    animation-duration: 10s;
    animation-delay: -158s;
    transform-origin: 3vw 25vh;
    box-shadow: 34vmin 0 5.073930457707828vmin currentColor;
}
.background span:nth-child(59) {
    color: #583C87;
    top: 494%;
    left: 93%;
    animation-duration: 16s;
    animation-delay: -107s;
    transform-origin: 12vw 8vh;
    box-shadow: -34vmin 0 4.5672425386182836vmin currentColor;
}




.background span:nth-child(60) {
    color: #914b29;
    top: 638%;
    left: 25%;
    animation-duration: 167s;
    animation-delay: -118s;
    transform-origin: 8vw -9vh;
    box-shadow: -37vmin 0 4.72308404058913vmin currentColor;
}
.background span:nth-child(61) {
    color: #bc2d08;
    top: 657%;
    left: 8%;
    animation-duration: 142s;
    animation-delay: -130s;
    transform-origin: 17vw -1vh;
    box-shadow: -34vmin 0 4.605214951106792vmin currentColor;
}
.background span:nth-child(62) {
    color: #E45A84;
    top: 655%;
    left: 4%;
    animation-duration: 43s;
    animation-delay: -128s;
    transform-origin: 2vw -7vh;
    box-shadow: 34vmin 0 4.677569591067222vmin currentColor;
}
.background span:nth-child(63) {
    color: #583C87;
    top: 669%;
    left: 45%;
    animation-duration: 96s;
    animation-delay: -67s;
    transform-origin: 16vw -17vh;
    box-shadow: 34vmin 0 4.905195624516644vmin currentColor;
}
.background span:nth-child(64) {
    color: #FFACAC;
    top: 683%;
    left: 11%;
    animation-duration: 40s;
    animation-delay: -2s;
    transform-origin: 9vw 13vh;
    box-shadow: -34vmin 0 4.545621916627195vmin currentColor;
}
.background span:nth-child(65) {
    color: #FFACAC;
    top: 683%;
    left: 76%;
    animation-duration: 102s;
    animation-delay: -41s;
    transform-origin: -2vw -18vh;
    box-shadow: -34vmin 0 4.519323476717205vmin currentColor;
}
.background span:nth-child(66) {
    color: #bc2d08;
    top: 689%;
    left: 23%;
    animation-duration: 111s;
    animation-delay: -30s;
    transform-origin: 12vw -24vh;
    box-shadow: -34vmin 0 5.102326201581666vmin currentColor;
}
.background span:nth-child(67) {
    color: #914b29;
    top: 631%;
    left: 79%;
    animation-duration: 75s;
    animation-delay: -133s;
    transform-origin: 2vw -15vh;
    box-shadow: 34vmin 0 4.274951778559119vmin currentColor;
}
.background span:nth-child(68) {
    color: #bc2d08;
    top: 697%;
    left: 91%;
    animation-duration: 58s;
    animation-delay: -100s;
    transform-origin: 21vw 0vh;
    box-shadow: -34vmin 0 4.257554960409644vmin currentColor;
}
.background span:nth-child(69) {
    color: #583C87;
    top: 614%;
    left: 75%;
    animation-duration: 22s;
    animation-delay: -143s;
    transform-origin: -18vw 15vh;
    box-shadow: 34vmin 0 4.529618569764583vmin currentColor;
}
.background span:nth-child(610) {
    color: #bc2d08;
    top: 645%;
    left: 20%;
    animation-duration: 71s;
    animation-delay: -27s;
    transform-origin: -19vw -12vh;
    box-shadow: 34vmin 0 5.140357580477675vmin currentColor;
}
.background span:nth-child(71) {
    color: #914b29;
    top: 663%;
    left: 84%;
    animation-duration: 57s;
    animation-delay: -106s;
    transform-origin: -22vw -13vh;
    box-shadow: -34vmin 0 4.976397818984253vmin currentColor;
}
.background span:nth-child(72) {
    color: #583C87;
    top: 614%;
    left: 13%;
    animation-duration: 61s;
    animation-delay: -62s;
    transform-origin: 10vw 21vh;
    box-shadow: -34vmin 0 4.713508509180956vmin currentColor;
}
.background span:nth-child(73) {
    color: #E45A84;
    top: 607%;
    left: 73%;
    animation-duration: 21s;
    animation-delay: -4s;
    transform-origin: 6vw -16vh;
    box-shadow: 34vmin 0 4.467105091756775vmin currentColor;
}
.background span:nth-child(74) {
    color: #583C87;
    top: 652%;
    left: 84%;
    animation-duration: 137s;
    animation-delay: -59s;
    transform-origin: 13vw -18vh;
    box-shadow: -34vmin 0 4.534312089203363vmin currentColor;
}
.background span:nth-child(75) {
    color: #914b29;
    top: 653%;
    left: 94%;
    animation-duration: 144s;
    animation-delay: -104s;
    transform-origin: -9vw -3vh;
    box-shadow: -34vmin 0 4.330266620127379vmin currentColor;
}
.background span:nth-child(76) {
    color: #914b29;
    top: 614%;
    left: 14%;
    animation-duration: 119s;
    animation-delay: -124s;
    transform-origin: 25vw -8vh;
    box-shadow: -34vmin 0 5.182863385351318vmin currentColor;
}
.background span:nth-child(77) {
    color: #E45A84;
    top: 651%;
    left: 34%;
    animation-duration: 63s;
    animation-delay: -118s;
    transform-origin: -11vw 10vh;
    box-shadow: 34vmin 0 4.2585086775251vmin currentColor;
}
.background span:nth-child(78) {
    color: #FFACAC;
    top: 695%;
    left: 41%;
    animation-duration: 10s;
    animation-delay: -158s;
    transform-origin: 3vw 25vh;
    box-shadow: 34vmin 0 5.073930457707828vmin currentColor;
}
.background span:nth-child(79) {
    color: #583C87;
    top: 694%;
    left: 93%;
    animation-duration: 16s;
    animation-delay: -107s;
    transform-origin: 12vw 8vh;
    box-shadow: -34vmin 0 4.5672425386182836vmin currentColor;
}