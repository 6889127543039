nav{
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform:translate(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8;
    border: 3rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
    z-index: 20;
}
nav a{
    background: transparent;
    padding: 1rem;
    border-radius: 50%;
    display: flex;
    font-size: 1.1rem;
}
nav a:hover{
    background: rgba(0, 0, 0, 0.4);
}
nav a.active{
    background: rgb(144, 144, 138);
    color: var(--color-bg-variant);
}