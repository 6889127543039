.projects{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2.5rem;
}
.project_items{
    background: var(--color-bg-variant);
    padding: 1rem;
    border-radius: 2rem;
    border:1px solid transparent;
    transition: var(--transition);
}
.project_items:hover{
    border-color:var(--color-primary-variant) ;
    background: rgb(30, 29, 29);
}
.project_items-image{
    border-radius: 1.5rem;
    overflow: hidden;
}
.exp_img{
    width: 15rem;
    height: 10rem;
    border-radius: 1.5rem;
}
.project_items h3{
    margin: 1.2rem 0 2rem;
}
@media screen and (max-width:1024px) {
    .projects{
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        position: relative;
        right: 2rem;
    }
    .exp_img{
        width: 13rem;
        height: 7rem;
        border-radius: 1.5rem;
    }
    .project_items{
        background: var(--color-bg-variant);
        padding: 1rem;
        border-radius: 2rem;
        border:1px solid transparent;
        transition: var(--transition);
        width: 15rem;
    }
}
@media screen and (max-width:600px) {
    .projects{
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        position: relative;
        right: 2rem;
    }
    .exp_img{
        width: 13rem;
        height: 7rem;
        border-radius: 1.5rem;
    }
    .project_items{
        background: var(--color-bg-variant);
        padding: 1rem;
        border-radius: 2rem;
        border:1px solid transparent;
        transition: var(--transition);
        width: 15rem;
    }
}