.test__card{
  background: var(--color-light);
  border-radius: 200px;
  height: 20rem;
}
.test__card::before{
    content: "";
    position: absolute;
    height: 52%;
    width: 100%;
    background-color:  rgb(30, 29, 29);
    border-radius: 20px 20px 0 0;
}
.Details1{
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    font-size: 1.23rem;
}
.Icons{
    color: var(--color-primary);
    font-size: 5.2rem;
    margin-top: 0.1rem;
    margin-bottom: 1rem;
    position: relative;
    z-index: 100;

}
.test__content{
 width:50%;
 margin-left: 25%;
}
@media screen and (max-width:600px) {
    .test__card{
        margin-top: 170%;
        background: var(--color-light);
        border-radius: 200px;
        height: 20rem;
      }
      .test__content{
        width: 11rem;
        position: absolute;
        right: 3rem;
        margin-top: -70%;
      }
      .Details1{
        display: flex;
        flex-direction: column;
        position: relative;
        align-items:center;
        font-size: 1.23rem;
    }
    h4{
        text-align: center;
    }
    .test__card::before{
        content: "";
        position: absolute;
        height: 32%;
        width: 100%;
        background-color:  rgb(30, 29, 29);
        border-radius: 20px 20px 0 0;
    }
}